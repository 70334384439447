import { getRedis, setRedis } from "../api/redis";
import { defineStore } from "pinia";

// for handling complex states
export const useFilterStore = defineStore("filter", () => {
  const selectedAttributeFilters = ref({});
  const selectedCategoryFilters = ref([]);
  const searchBrandAttribute = ref([]);
  const searchLicenseAttribute = ref([]);
  const selectedSort = ref([]);
  const selectedReleaseFilters = ref();
  const selectedAttributeUI = ref({});
  const isMobile = ref(true);
  const emailModal = ref(false);
  const isMountedSearch = ref(false);
  const breadCrumbs = ref([
    {
      "products-id-name": [{ text: "Home", path: "/" }],
    },
    // { index: [{ text: "Home", path: "/" }] },
    {
      "account-cart": [
        { text: "Home", path: "/" },
        {
          text: "account-cart",
          path: "/account/cart",
        },
      ],
    },
    {
      "account-checkout": [
        { text: "Home", path: "/" },
        {
          text: "account-checkout",
          path: "/account/checkout",
        },
      ],
    },
    {
      "account-contact": [
        { text: "Home", path: "/" },
        {
          text: "account-contact",
          path: "/account/contact",
        },
      ],
    },
    {
      "account-dashboard": [
        { text: "Home", path: "/" },
        {
          text: "account-dashboard",
          path: "/account/dashboard",
        },
      ],
    },
    {
      "account-forget-password": [
        { text: "Home", path: "/" },
        {
          text: "account-forget-password",
          path: "/account/forget-password",
        },
      ],
    },
    {
      "account-login": [
        { text: "Home", path: "/" },
        {
          text: "account-login",
          path: "/account/login",
        },
      ],
    },
    {
      "account-post-signup-id": [
        { text: "Home", path: "/" },
        {
          text: "account-post-signup-id",
          path: "/account/post-signup/:id()",
        },
      ],
    },
    {
      "account-pre-signup": [
        { text: "Home", path: "/" },
        {
          text: "account-pre-signup",
          path: "/account/pre-signup",
        },
      ],
    },
    {
      "account-profile": [
        { text: "Home", path: "/" },
        {
          text: "account-profile",
          path: "/account/profile",
        },
      ],
    },
    {
      "contact-us": [
        { text: "Home", path: "/" },
        {
          text: "contact-us",
          path: "/contact-us",
        },
      ],
    },
    {
      "order-backorders": [
        { text: "Home", path: "/" },
        {
          text: "order-backorders",
          path: "/order/backorders",
        },
      ],
    },
    {
      "order-cartmanagement": [
        { text: "Home", path: "/" },
        {
          text: "order-cartmanagement",
          path: "/order/cartmanagement",
        },
      ],
    },
    {
      "order-deliverables": [
        { text: "Home", path: "/" },
        {
          text: "order-deliverables",
          path: "/order/deliverables",
        },
      ],
    },
    {
      "order-downloadhistory": [
        { text: "Home", path: "/" },
        {
          text: "order-downloadhistory",
          path: "/order/downloadhistory",
        },
      ],
    },
    {
      "order-history": [
        { text: "Home", path: "/" },
        {
          text: "order-history",
          path: "/order/history",
        },
      ],
    },
    {
      "order-opendocs": [
        { text: "Home", path: "/" },
        {
          text: "order-opendocs",
          path: "/order/opendocs",
        },
      ],
    },
    {
      "order-searchhistory": [
        { text: "Home", path: "/" },
        {
          text: "order-searchhistory",
          path: "/order/searchhistory",
        },
      ],
    },
    {
      "order-unavailableDeliveries": [
        { text: "Home", path: "/" },
        {
          text: "order-unavailableDeliveries",
          path: "/order/unavailableDeliveries",
        },
      ],
    },
    {
      "our-history": [
        { text: "Home", path: "/" },
        {
          text: "our-history",
          path: "/our-history",
        },
      ],
    },
    {
      "our-services": [
        { text: "Home", path: "/" },
        {
          text: "our-services",
          path: "/our-services",
        },
      ],
    },
    {
      pricedown: [
        { text: "Home", path: "/" },
        {
          text: "pricedown",
          path: "/pricedown",
        },
      ],
    },
    {
      "products-search": [
        { text: "Home", path: "/" },
        {
          text: "products-search",
          path: "/products/search",
        },
      ],
    },
    {
      "user-profile": [
        { text: "Home", path: "/" },
        {
          text: "user-profile",
          path: "/user/profile",
        },
      ],
    },
  ]);
  const productInfoBreadCrumbs = ref();
  const page = ref(1);
  const priceRange = ref({
    minAmount: 1,
    maxAmount: 9999,
  });
  const showNewsLetterModal = ref(false);
  const showQuickView = reactive({ show: false, item: {} });
  const expandedCategoryFilters = ref({});
  // internal state for Category component
  const categoryfilterState = ref({
    parent: {},
    child: {},
    grandchild: {},
  });
  const priceKey = "dvd_suggested_price";
  const setSelectedAttributeFilters = (newValue) => {
    if (newValue && Object.keys(newValue).length) {
      setSelectedReleaseFilters(null);
    }
    selectedAttributeFilters.value = newValue;
  };
  const setSelectedReleaseFilters = (newValue) => {
    selectedReleaseFilters.value = newValue;
  };
  const setShowQuickView = ({ show, item }) => {
    showQuickView.show = show;
    showQuickView.item = item;
  };
  const setSelectedCategoryFilters = (newCatValue) => {
    if (newCatValue?.length) {
      setSelectedReleaseFilters(null);
    }
    selectedCategoryFilters.value = newCatValue;
  };

  const setExpandedCategoryFilters = (newValue) => {
    expandedCategoryFilters.value = newValue;
  };
  const setCategoryfilterState = (newValue) => {
    categoryfilterState.value = newValue;
  };
  const setAttributeUI = (newValue) => {
    selectedAttributeUI.value = newValue;
  };
  const setShowNewsLetterModal = (newValue) => {
    showNewsLetterModal.value = newValue;
  };
  const setPage = (newPage) => {
    page.value = newPage;
  };
  const setPrice = ({ min, max }) => {
    priceRange.value = {
      minAmount: min,
      maxAmount: max,
    };
  };
  const setBreadCrumbs = ({ dataArray, name, index }) => {
    breadCrumbs.value[index][name] = dataArray;
  };
  const setProductInfoBreadCrumbs = ({ dataArray }) => {
    productInfoBreadCrumbs.value = dataArray;
  };
  const setIsMobile = (v) => {
    isMobile.value = !isMobile.value;
  };
  const setIsMountedSearch = (value) => {
    isMountedSearch.value = value;
  };
  const setEmailModal = (value) => {
    emailModal.value = value;
  };
  const setSelectedSort = (value) => {
    selectedSort.value = value;
  };
  const setSearchBrandAttribute = (value) => {
    searchBrandAttribute.value = value;
  };
  const setSearchLicenseAttribute = (value) => {
    searchLicenseAttribute.value = value;
  };
  return {
    selectedAttributeFilters,
    selectedCategoryFilters,
    searchBrandAttribute,
    searchLicenseAttribute,
    expandedCategoryFilters,
    categoryfilterState,
    selectedAttributeUI,
    selectedReleaseFilters,
    selectedSort,
    showNewsLetterModal,
    showQuickView,
    page,
    priceRange,
    breadCrumbs,
    isMobile,
    isMountedSearch,
    priceKey,
    setShowQuickView,
    setAttributeUI,
    setCategoryfilterState,
    setExpandedCategoryFilters,
    setSelectedAttributeFilters,
    setSelectedCategoryFilters,
    setSelectedReleaseFilters,
    setShowNewsLetterModal,
    setPage,
    setPrice,
    setBreadCrumbs,
    setProductInfoBreadCrumbs,
    setIsMountedSearch,
    setSelectedSort,
    productInfoBreadCrumbs,
    setIsMobile,
    emailModal,
    setEmailModal,
    setSearchBrandAttribute,
    setSearchLicenseAttribute,
  };
});
